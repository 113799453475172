import React, { FC } from 'react';

import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import { Reviews } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import CardList from '../CardList';
import List from '../List';
import Disclaimer from 'components/Disclaimer';
import BannerOne from '../BannerOne';
import UpsellSectionOne from './UpsellSectionOne';
import CardListWithSubtitles from '../CardListWithSubtitles';

interface FastingMadeEasyProps {}

const Container = styled.div``;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f3f3f8;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const ListContainer = styled.div`
  width: 100%;
  background-color: #f3f3f8;
  display: flex;
  justify-content: center;
`;

const ListStyledBot = styled(List)`
  max-width: 42.5rem;
`;

const FastingMadeEasy: FC<FastingMadeEasyProps> = ({
  paymentSteps,
  userHasBoughtPaperbackBook,
  planSection,
  planSection2,
  PlanSectionComponent,
  upsellSectionOne,
  reviewsSection,
  cardList,
  list,
  disclaimerSection,
  cardListWithSubtitles,
  bannerOne,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 4rem 1rem;
    @media ${tablet} {
      padding: 2.25rem 1rem;
    }
  `;

  return (
    <>
      {bannerOne && <BannerOne {...bannerOne} />}
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <UpsellSectionOne {...upsellSectionOne} />
      <PlanSectionComponentStyled {...planSection} />
      {cardList && <CardList {...cardList} />}
      {cardListWithSubtitles && (
        <CardListWithSubtitles {...cardListWithSubtitles} />
      )}
      {list && <List {...list} />}
      <Reviews {...reviewsSection} />
      <PlanSectionComponentStyled {...planSection2} />
    </>
  );
};

export default FastingMadeEasy;
